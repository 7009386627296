import { Link } from '@remix-run/react';

import { type GamePack } from '../../../src/types/game';
import { GamePackCard } from '../GamePack/GamePackCollection';
import {
  ComingSoonGamePackBadge,
  FreeGamePackBadge,
  GamePackPlayable,
  NewGamePackBadge,
  ThumbsUpBadge,
} from '../GamePack/GamePackShared';
import { makeAnonPackUrl } from '../GamePack/utils';
import { useAnonFeatureChecker } from '../hooks/useAnonFeatureChecker';

export function AnonGamePackCard(props: {
  pack: GamePack;
  onClick?: () => void;
}) {
  const { pack, onClick } = props;

  const features = useAnonFeatureChecker();

  return (
    <Link to={makeAnonPackUrl(pack)} onClick={(e) => e.preventDefault()}>
      <GamePackCard
        gamePack={pack}
        onClick={onClick}
        badges={
          <>
            <NewGamePackBadge gamePack={pack} />
            <ComingSoonGamePackBadge gamePack={pack} />
            <FreeGamePackBadge gamePack={pack} features={features} />
            <ThumbsUpBadge gamePack={pack} />
          </>
        }
        bottomAccessory={<GamePackPlayable {...props} />}
        styles={{
          size: 'w-full',
          badgesTranslateClassName: '-translate-y-1/4',
        }}
        lazyLoadCover
      />
    </Link>
  );
}
